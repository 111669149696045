import request from '@/utils/request';

export function payList(encrypted) {
  return request({
    url: `/api/insurePay/cmb/${encrypted}`,
    method: 'get',
  });
}

export function noOrderList(encrypted) {
  return request({
    url: `/api/insurePay/js/${encrypted}`,
    method: 'get',
  });
}

export function pay(encrypted, data) {
  return request({
    url: `/api/insurePay/cmbConfirm/${encrypted}`,
    method: 'post',
    data,
  });
}
export function cmbSubList(encrypted) {
  return request({
    url: `/api/insurePay/cmbSub/${encrypted}`,
    method: 'get',
  });
}



export default { payList, noOrderList, pay,cmbSubList };
