<template>
  <div class="insurePay">
    <div class="title">
      收银台
    </div>
    <div class="payMsg">
      <div class="left">
        <span class="text">请及时完成支付</span>
        <span>业务流水号： {{ userAcctInfo.orderNo }}</span>
      </div>
      <div class="right">
        <div class="text1">
          <span class="text">您的保额(元)：</span>
          <span class="red">{{ userAcctInfo.sumAmount }}</span>
        </div>
        <div class="text2">
          <span class="text">需支付保费(元)：</span>
          <span class="red">{{ userAcctInfo.sumPremium }}</span>
        </div>
      </div>
    </div>
    <div class="payImg">
      <span class="text">请选择支付方式：</span>
      <div class="img">
        <div class="payType">
          <span>关联流水:</span>
          <img
            @click="gopage(`/pay?encrypted=${encrypted}`)"
            src="../../assets/image/cmbc.png"
            alt=""
          >
        </div>
        <div class="line" />
        <div class="payType">
          <span>线下支付:</span>
          <img
            @click="open"
            src="../../assets/image/offlinepay.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="tips">
      温馨提示：<br>
      招商银行：请企业选择对应的汇款流水进行支付，汇款金额需与支付金额相同，方可支付成功。
    </div>
  </div>
</template>

<script>
import { payList } from '@/api/pay';
import { Modal } from 'ant-design-vue';
export default {
  data() {
    return {
      encrypted: null,
      userAcctInfo: {},
    };
  },
  created() {
    this.encrypted = this.$route.query.encrypted;
    this.refresh();
  },
  methods: {
    refresh() {
      payList(this.encrypted).then(res => {
        this.userAcctInfo = res.data.userAcctInfo;
      });
    },
    gopage(url) {
      this.$router.push({ path: url });
    },
    open() {
      window.open(`${process.env.VUE_APP_FIRST_URL}/api/insurePay/offline/${this.encrypted}`, '_self');
    },
    btn() {
      Modal.info({
        title: '敬请期待！',
        okText: '取消',
        onOk() {
          console.log('ok');
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.insurePay {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  .title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .payMsg {
    width: 60vw;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      .text {
        font-size: 38px;
        font-weight: normal;
        margin-bottom: 20px;
      }
      span {
        font-size: 18px;
      }
    }
    .right {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        .text {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .red {
          font-size: 40px;
          font-weight: normal;
          color: red;
        }
      }
    }
  }
  .payImg {
    width: 60vw;
    margin-top: 60px;
    .text {
      font-size: 18px;
    }
    .img {
      display: flex;
      justify-content: space-between;
      padding: 0 120px;
      margin-top: 30px;
      .line {
        width: 1px;
        height: 145px;
        background-color: #ccc;
      }
      .payType {
        display: flex;
        span {
          font-size: 18px;
          margin-right: 5px;

        }
        img {
          width: 140px;
          height: 140px;
          cursor: pointer;
        }
      }
    }
  }
  .tips {
    width: 60vw;
    margin-top: 80px;
    font-size: 18px;
  }
}

</style>
